@font-face {
  font-family: 'BlackerSansProBold';
  src: local('BlackerSansProBold'),
  url(./asset/font/blacker-sans-pro/BlackerSansPro-Bold.woff) format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'BlackerSansProHeavy';
  src: local('BlackerSansProHeavy'),
  url(./asset/font/blacker-sans-pro/BlackerSansPro-Heavy.woff) format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'TimesNewRomanRegular';
  src: local('TimesNewRomanRegular'),
  url(./asset/font/timesnewsroman/TimesNewRomanMTStd-Regular.woff) format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'TimesNewRomanBold';
  src: local('TimesNewRomanBold'),
  url(./asset/font/timesnewsroman/TimesNewRomanMTStd-Bold.woff) format('woff');
  font-display: swap;
}